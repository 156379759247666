const companies = [
  {
    id: 1,
    name: "ateris s.a.",
    date: "feb 2022 - present",
    responsibilities: [
      "maintaining and enhancing an enterprise management application",
      "creating new functionalities and api endpoints",
      "deploying the application on linux and windows servers",
      "building integrations with external systems",
      "representing the company at the international conference",
      "ensuring compliance with wcag 2.1 accessibility standards",
      "unit testing",
    ],
  },
  {
    id: 2,
    name: "freelance",
    date: "jul 2021 - present",
    responsibilities: [
      "developing software applications for clients according to their specifications",
      "hosting the software on a cloud server",
      "providing technical support and troubleshooting for the software",
      "integrating software with other systems and APIs",
      "collaborating with clients to understand their needs and requirements for the project",
    ],
  },
  {
    id: 3,
    name: "devsdata",
    date: "feb 2021 - nov 2021",
    responsibilities: [
      "creating web applications for external clients",
      "developing internal scripts and chrome extensions to automate recruitment processes",
      "creating web scrapers to extract data from external websites",
      "processing candidate data",
      "refactor existing code to improve performance and maintainability",
      "managing team of international engineers",
    ],
  },
];

export default companies;
