import React, { useEffect } from "react";

function PreLoader() {
  // create function that will update css --circle-radius variable with *=1.07 every 0.01s until till it reaches 150%
  const updateMask = () => {
    const root = document.querySelector(":root");
    let maskValue = 0.1;
    let isPreloaderRemover = false;
    const interval = setInterval(() => {
      maskValue *= 1.07;
      root.style.setProperty("--circle-radius", `${maskValue}%`);
      if (maskValue >= 150 && !isPreloaderRemover) {
        clearInterval(interval);
        isPreloaderRemover = true;
        document.querySelector("body").style.overflow = "auto";
        document.querySelector(".preloader").remove();
      }
    }, 10);
  };

  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden";
    setTimeout(() => {
      updateMask();
    }, 500);
  });

  return <section className="preloader" />;
}

export default PreLoader;
