import React from "react";

function SkillCategory({ categoryName, categoryItems }) {
  return (
    <div className="skill-category">
      <p className="category__name">{categoryName}</p>
      {categoryItems.map((key, index) => (
        <p key={key}>{key}</p>
      ))}
    </div>
  );
}

export default SkillCategory;
