import React from "react";

function Section({ children, className, containerClassName }) {
  return (
    <section className={`section ${className}`}>
      <div className={`container section__container ${containerClassName}`}>
        {children}
      </div>
    </section>
  );
}

export default Section;
