import React from "react";

function Company({ company }) {
  return (
    <div className="company">
      <div className="company__info">
        <div className="company-info__container">
          <p className="company__name">{company.name}</p>
          <p className="company__date">{company.date}</p>
        </div>
      </div>
      <div className="company__responsibilities">
        <p className="company__name--mobile">{company.name}</p>
        <p className="company__date--mobile">({company.date})</p>
        {company.responsibilities.map((responsibility) => (
          <p className="responsibilities__item" key={responsibility}>
            {responsibility}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Company;
