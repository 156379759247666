import "aos/dist/aos.css";
import "./assets/App.css";
import React, { useState, useEffect } from "react";
import AOS from "aos";
import LandingPage from "./pages/landingPage";
import AboutMe from "./pages/aboutMe";
import Experience from "./pages/experience";
import Contact from "./pages/contact";
import PreLoader from "./data/preloader";

function App() {
  useEffect(() => {
    const link = document.querySelectorAll(".hover__wrapper");
    const cursor = document.querySelector(".cursor");
    let counter = 0;

    function cursorHoverAnimation(b, e) {
      const hovredText = b.querySelector(".hover-text");
      if (hovredText === null) {
        return;
      }
      cursor.classList.add("cursor--hover");
      const { offsetX: x, offsetY: y } = e;
      const { offsetWidth: width, offsetHeight: height } = b;

      const move = 25;
      const xMove = (x / width) * (move * 2) - move;
      const yMove = (y / height) * (move * 2) - move;
      counter += 1;

      hovredText.style.transform = `translate(${xMove}px, ${yMove}px)`;

      // counter is set to prevent the animation from running too fast on entering hover element
      if (e.type === "mousemove" && counter >= 10) {
        hovredText.style.transition = "";
      }

      if (e.type === "mouseleave") {
        hovredText.style.transition = "transform 0.1s ease";
        hovredText.style.transform = "";
        cursor.classList.remove("cursor--hover");
        counter = 0;
      }
    }

    const editCursor = (e) => {
      const { clientX: x, clientY: y } = e;
      cursor.style.left = `${x}px`;
      cursor.style.top = `${y}px`;
    };

    link.forEach((b) =>
      b.addEventListener("mousemove", cursorHoverAnimation.bind(null, b), false)
    );
    link.forEach((b) =>
      b.addEventListener(
        "mouseleave",
        cursorHoverAnimation.bind(null, b),
        false
      )
    );
    window.addEventListener("mousemove", editCursor);
    AOS.init();
  });
  return (
    <div className="App">
      <PreLoader />
      <LandingPage />
      <AboutMe />
      <Experience />
      <Contact />
    </div>
  );
}

export default App;
