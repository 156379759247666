import React from "react";
import Section from "../components/section";

function Contact() {
  return (
    <Section className="section--contact" containerClassName="section--contact">
      <p className="contact__text">think i would fit in your project?</p>
      <div className="contact-wrapper">
        <div data-aos="fade-right" className="contact__item">
          <a href="https://www.linkedin.com/in/dawid-w-3441011b7/">linkedin</a>
        </div>
        <div data-aos="fade-right" className="contact__item">
          <a href="mailto:praca.dawidwk@gmail.com">praca.dawidwk@gmail.com</a>
        </div>
        <div data-aos="fade-right" className="contact__item">
          <a href="https://github.com/DawidWK/">github</a>
        </div>
      </div>
    </Section>
  );
}

export default Contact;
