import React from "react";
import Section from "../components/section";
import { ReactComponent as ReactLogo } from "../assets/svg/arrow.svg";

function LandingPage() {
  return (
    <Section className="section--dark" containerClassName="container--landing">
      <div className="landing__text">
        <div className="hover__wrapper">
          <h1 className="landing__title hover-text">DAWID</h1>
        </div>
        <p className="landing__subtitle">the fullstack dev</p>
      </div>
      <ReactLogo className="landing__arrow" />
    </Section>
  );
}

export default LandingPage;
