import React from "react";
import Section from "../components/section";
import SkillCategory from "../components/skillCategory";
import skills from "../data/skills";

function AboutMe() {
  return (
    <Section containerClassName="section--about-me">
      <div className="section__column section__column--left">
        <p className="about-me__floating-text">about me</p>
      </div>
      <div className="section__column section__column--right">
        <div className="about-me__info">
          <p>
            i am a fullstack developer with 3 years of commercial experience in
            web development
          </p>
          <p>
            i have experience leading teams, managing projects and working with
            a variety of clients.
          </p>
          <p>i have worked on projects using technologies such as</p>
        </div>
        <div className="about-me__skills">
          {Object.keys(skills).map((key) => (
            <SkillCategory
              key={skills[key]}
              categoryName={key}
              categoryItems={skills[key]}
            />
          ))}
        </div>
      </div>
    </Section>
  );
}

export default AboutMe;
