import React, { useState, useEffect } from "react";
import Section from "../components/section";
import Company from "../components/company";
import companies from "../data/companies";

function Experience() {
  function getPosition(element) {
    let xPosition = 0;
    let yPosition = 0;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent; // eslint-disable-line no-param-reassign
    }

    return { x: xPosition, y: yPosition };
  }

  function boldCurrentCompany() {
    const trackingBall = document.querySelector(".tracking-ball");
    const companiesBullet = document.querySelectorAll(
      ".responsibilities__item"
    );
    const companiesArray = Array.from(companiesBullet);
    const trackingBallTop = getPosition(trackingBall).y;
    const trackingBallBottom =
      getPosition(trackingBall).y + trackingBall.offsetHeight;

    companiesArray.forEach((company, index) => {
      const companyTop = getPosition(company).y;
      const companyBottom = getPosition(company).y + company.offsetHeight;

      if (
        (trackingBallTop >= companyTop && trackingBallTop <= companyBottom) ||
        (trackingBallBottom >= companyTop &&
          trackingBallBottom <= companyBottom)
      ) {
        company.classList.add("company--bold");
      }
      if (
        (trackingBallTop < companyTop || trackingBallTop > companyBottom) &&
        (trackingBallBottom < companyTop || trackingBallBottom > companyBottom)
      ) {
        company.classList.remove("company--bold");
      }
    });
  }

  useEffect(() => {
    // create a new scroll event that bolds the current company paragraf when tracking ball is within the company paragraf Y axis
    window.addEventListener("scroll", () => {
      boldCurrentCompany();
    });
  }, []);

  return (
    <Section
      className="section--dark section--experience"
      containerClassName="container--experiance"
    >
      <div className="experiance__line">
        <div className="tracking-ball-wrapper">
          <div className="tracking-ball" />
        </div>
      </div>
      {companies.map((company) => (
        <Company key={company.id} company={company} />
      ))}
    </Section>
  );
}

export default Experience;
